import React from 'react'
import i18next from 'i18next'

import { EmployeeWithContactDetails } from 'agency/types/employees'
import { DocumentGroupKind } from 'agency/types/document_groups'
import DocumentGroupKindsSelect from 'agency/components/DocumentGroupKindsSelect'
import Text from 'components/Text'
import Button from 'components/Button'
import Icon from 'components/Icon'
import Divider from 'components/Divider'
import { capitalizeFirstLetter } from 'utils/string'
import { useAddDocumentGroupRequests } from 'agency/queries/documents'
import CirclesIllustration from './CirclesIllustration'

type DocumentsProps = {
  employee: EmployeeWithContactDetails
  onNext: () => void
}

const Documents = ({ employee, onNext }: DocumentsProps) => {
  const { mutate: addDocumentGroupRequest } = useAddDocumentGroupRequests(employee.agency_employee_id, {
    onSuccess: () => onNext(),
  })
  const [selectedDocumentKinds, setSelectedDocumentKinds] = React.useState<DocumentGroupKind[]>(
    window.document_group_kinds.filter((documentKind: DocumentGroupKind) => documentKind.default)
  )

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault()
        addDocumentGroupRequest(selectedDocumentKinds.map((documentKind) => documentKind.slug))
      }}
      className="h-full"
    >
      <div className="flex flex-col justify-between h-full">
        <div className="p-6 overflow-y-auto">
          <div className="relative">
            <CirclesIllustration className="absolute -top-6 -left-6" />
            <Icon name="check_circle" size="xlarge" uiStyle="success" className="mb-10 " />
          </div>
          <Text type="title" className="mb-2">
            {i18next.t('agency.home.employee_invitation.invitation_sent', {
              name: [capitalizeFirstLetter(employee.first_name), capitalizeFirstLetter(employee.last_name)].join(' '),
            })}
          </Text>
          <div className="text-textSecondary flex items-center gap-2">
            <Icon name="mail" />
            <Text type="text">{employee.email}</Text>
          </div>
          <Divider className="my-6" />
          <Text type="subtitle" className="mb-2">
            {i18next.t('agency.home.employee_invitation.choose_document_kind')}
          </Text>
          <Text type="subtext" className="mb-4">
            {i18next.t('agency.home.employee_invitation.choose_document_kind_info')}
          </Text>
          <DocumentGroupKindsSelect
            selectedDocumentKinds={selectedDocumentKinds}
            setSelectedDocumentKinds={setSelectedDocumentKinds}
          />
        </div>
        <div className="flex flex-col pb-6">
          <Divider className="my-6" />
          <Button type="submit" name="ask_document_list" className="self-center">
            {i18next.t('agency.home.employee_invitation.validate_document_list')}
          </Button>
        </div>
      </div>
    </form>
  )
}

export default Documents
