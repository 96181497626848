import useAgency from 'agency/contexts/AgencyContext'
import { EmployeeWithContactDetails } from 'agency/types/employees'
import IconButton from 'components/IconButton'
import Text from 'components/Text'
import i18next from 'i18next'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { capitalizeFirstLetter } from 'utils/string'

type EmployeeProfileHeaderProps = {
  employee: EmployeeWithContactDetails
}

const EmployeeProfileHeader = ({ employee }: EmployeeProfileHeaderProps) => {
  const navigate = useNavigate()
  const { currentAgency } = useAgency()

  let employeeInformations = employee.email

  if (employee.phone_number) {
    employeeInformations += ` - ${employee.phone_number}`
  }

  const onClick = () => {
    if (!currentAgency) return

    navigate(`/agencies/${currentAgency.id}/agency_employees`)
  }

  return (
    <div id="employee_profile_header" className="bg-white py-5 px-14 w-screen items-center">
      <div className="flex items-center gap-4">
        <IconButton name="arrow_back" label={i18next.t('common.go_back')} onClick={onClick} />
        <Text type="title" className="grow">
          {employee.last_name.toUpperCase()} {capitalizeFirstLetter(employee.first_name)}
        </Text>
        <Text type="subtext">{employeeInformations}</Text>
      </div>
    </div>
  )
}

export default EmployeeProfileHeader
