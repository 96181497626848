import React from 'react'
import Modal from 'react-modal'
import i18next from 'i18next'

import DocumentViewer from 'components/DocumentViewer'
import Icon from 'components/Icon'
import IconButton from 'components/IconButton'
import Divider from 'components/Divider'
import Text from 'components/Text'

import DocumentDownloadButton from 'components/DocumentDownloadButton'
import { useDocument } from 'agency/queries/documents'

type TDocumentPreviewProps = {
  documentId: string
  agencyEmployeeId: string
  closePreview: () => void
}

const DocumentPreview = ({ documentId, agencyEmployeeId, closePreview }: TDocumentPreviewProps) => {
  const { data: document } = useDocument(agencyEmployeeId, documentId)
  return (
    <Modal
      overlayClassName="items-end fixed inset-0"
      className="h-screen w-1/2 bg-white absolute top-0 right-0 border-l shadow border-l-borderMedium"
      isOpen
      onRequestClose={closePreview}
    >
      <div className="flex flex-col h-screen">
        <div className="p-6 flex items-center justify-between">
          <Icon name="file_present" />
          <Text className="grow" type="subtitle">
            {document?.label}
          </Text>
          <div className="flex gap-2 items-center">
            {document && <DocumentDownloadButton file={document} />}
            <IconButton name="close" label={i18next.t('common.close')} onClick={closePreview} />
          </div>
        </div>
        <Divider />
        <div className="bg-backgroundPrimary h-full">
          {document && <DocumentViewer document={document} className="w-full h-4/5" mobileDisplayDisabled />}
          {/* TODO implement the preview like in the component DocumentUpload */}
        </div>
      </div>
    </Modal>
  )
}

export default DocumentPreview
