import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useEmployee } from 'agency/queries/employees'
import { DocumentGroup } from 'agency/types/document_groups'
import EmployeeProfileHeader from './EmployeeProfile/EmployeeProfileHeader'
import DocumentGroupList from './EmployeeProfile/DocumentGroupList'
import DocumentPreview from './EmployeeProfile/DocumentPreview'
import DocumentUpload from './EmployeeProfile/DocumentUpload'

const EmployeeProfile = () => {
  const { agencyEmployeeId } = useParams()
  const [displayDocumentPreviewForDocumentId, setDisplayDocumentPreviewForDocumentId] = useState<string>('')
  const [documentGroupsUploadChoices, setDocumentGroupsUploadChoices] = useState<DocumentGroup[]>([])

  const { data: employee } = useEmployee(agencyEmployeeId)

  return agencyEmployeeId && employee ? (
    <div className="h-full flex flex-col">
      {displayDocumentPreviewForDocumentId ? (
        <DocumentPreview
          documentId={displayDocumentPreviewForDocumentId}
          agencyEmployeeId={agencyEmployeeId}
          closePreview={() => setDisplayDocumentPreviewForDocumentId('')}
        />
      ) : null}

      {documentGroupsUploadChoices.length > 0 ? (
        <DocumentUpload
          documentGroups={documentGroupsUploadChoices}
          agencyEmployeeId={agencyEmployeeId}
          closePreview={() => setDocumentGroupsUploadChoices([])}
        />
      ) : null}
      <EmployeeProfileHeader employee={employee} />
      <div className="bg-backgroundNeutralLight h-full py-6 pb-20 px-14 flex justify-between gap-6">
        <DocumentGroupList
          documentGroups={employee.document_groups}
          agencyEmployeeId={agencyEmployeeId}
          setDisplayDocumentPreviewForDocumentId={setDisplayDocumentPreviewForDocumentId}
          setDocumentGroupsUploadChoices={setDocumentGroupsUploadChoices}
        />
      </div>
    </div>
  ) : null
}

export default EmployeeProfile
