import React from 'react'
import Tag, { TagUiStyle } from 'components/Tag'
import i18next from 'i18next'
import { StatusTag, StatusTagsByCount } from 'agency/types/employees'

const TextByStatusTag: Record<StatusTag, string> = {
  missing: 'agency.home.employee_list.employee_status_tag.missing',
  complete: 'agency.home.employee_list.employee_status_tag.complete',
  fraudulent: 'agency.home.employee_list.employee_status_tag.fraudulent',
  added: 'agency.home.employee_list.employee_status_tag.added',
  finalizable: 'agency.home.employee_list.employee_status_tag.finalizable',
}

const TagByStatusTag: Record<StatusTag, TagUiStyle> = {
  missing: 'error',
  fraudulent: 'error',
  complete: 'success',
  added: 'info',
  finalizable: 'warning',
}

const StatusTags = ({ statusTags }: { statusTags: StatusTagsByCount }) => {
  const tags: StatusTag[] = Object.keys(statusTags) as StatusTag[]

  return tags.map((status: StatusTag) => (
    <Tag key={status} uiStyle={TagByStatusTag[status]} withDot className="mr-2">
      {i18next.t(TextByStatusTag[status], { count: statusTags[status] })}
    </Tag>
  ))
}

export default StatusTags
