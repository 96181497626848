import React, { useState } from 'react'
import i18next from 'i18next'
import { DocumentGroup } from 'agency/types/document_groups'
import useAgency from 'agency/contexts/AgencyContext'
import { useEmployee } from 'agency/queries/employees'
import IconTextButton from 'components/IconTextButton'
import Text from 'components/Text'
import { downloadFile } from 'utils/download-file'
import RequestModal from './RequestModal'

type DocumentGroupListHeaderProps = {
  documentGroups: DocumentGroup[]
  agencyEmployeeId: string
  setDocumentGroupsUploadChoices: (documentGroups: DocumentGroup[]) => void
}

const DocumentGroupListHeader = ({
  setDocumentGroupsUploadChoices,
  documentGroups,
  agencyEmployeeId,
}: DocumentGroupListHeaderProps) => {
  const { currentAgencyId } = useAgency()
  const [isRequestModalOpen, setIsRequestModalOpen] = useState(false)

  const { isFetched } = useEmployee(agencyEmployeeId)

  const downloadAllDocuments = () => {
    downloadFile(`/agencies/${currentAgencyId}/agency_employees/${agencyEmployeeId}/documents_archive_download.zip`)
  }

  return (
    <div className="px-4 py-3 flex items-center justify-between gap-x-2">
      <div>
        <Text type="subtitle">{i18next.t('agency.employee_profile.document_section.title')}</Text>
      </div>
      <div className="flex items-center gap-2">
        {documentGroups.length > 0 && (
          <IconTextButton
            label={i18next.t('agency.employee_profile.document_section.import_document')}
            icon="cloud_upload"
            onClick={() => setDocumentGroupsUploadChoices(documentGroups)}
            responsive
          />
        )}
        <IconTextButton
          label={i18next.t('agency.employee_profile.document_section.ask_for_document')}
          icon="format_list_bulleted"
          onClick={() => setIsRequestModalOpen(true)}
          responsive
        />
        <IconTextButton
          label={i18next.t('agency.employee_profile.document_section.download_all')}
          icon="download"
          onClick={downloadAllDocuments}
          responsive
        />
      </div>
      {isRequestModalOpen && isFetched ? (
        <RequestModal
          closeModal={() => setIsRequestModalOpen(false)}
          agencyEmployeeId={agencyEmployeeId}
          existingDocumentGroupKindSlugs={documentGroups.map((documentGroup) => documentGroup.kind_slug)}
        />
      ) : null}
    </div>
  )
}

export default DocumentGroupListHeader
