import React from 'react'
import i18next from 'i18next'

import httpClient from 'utils/http-client'
import CommonForgotPassword from 'components/ForgotPassword'
import Text from 'components/Text'

const Description = () => <Text type="text">{i18next.t('agency.forgot_password.description')}</Text>

const ForgotPassword = () => {
  const resetPassword = (email: string) =>
    httpClient<void>('/agent/password', {
      method: 'post',
      body: { email },
    })
  const title = i18next.t('agency.forgot_password.title')
  return (
    <div className="bg-backgroundPrimary min-h-screen flex">
      <CommonForgotPassword
        className="px-6 py-6 max-w-3xl mx-auto"
        title={title}
        description={<Description />}
        resetPassword={resetPassword}
      />
    </div>
  )
}

export default ForgotPassword
