import React from 'react'
import i18next from 'i18next'
import Text from 'components/Text'
import Button from 'components/Button'
import Icon from 'components/Icon'
import { EmployeeWithInvitationStatus, RecordStatus } from 'agency/types/employees'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import Pill from 'components/Pill'

export const countEmployeeForStatus = (employees: EmployeeWithInvitationStatus[], status: RecordStatus) =>
  employees.filter((employee) => employee.status === status).length

type EmployeeListHeaderProps = {
  employees: EmployeeWithInvitationStatus[]
  currentTab: RecordStatus
  setCurrentTab: (tab: RecordStatus) => void
  setOpenInvitationModal: (isOpen: boolean) => void
}

type TabProps = {
  label: string
  count: number
  isActive: boolean
  tab: RecordStatus
  setCurrentTab: (tab: RecordStatus) => void
}

const Tab = ({ label, count, isActive, tab, setCurrentTab }: TabProps) => (
  <Link to={`#${tab}`} onClick={() => setCurrentTab(tab)}>
    <div className={classNames('flex gap-2 items-center pb-4', { 'border-b-2 border-primary': isActive })}>
      <Text
        type="text"
        className={classNames('font-medium', {
          'text-textSecondary': !isActive,
          'text-primary': isActive,
        })}
      >
        {label}
      </Text>
      <Pill count={count} isActive={isActive} />
    </div>
  </Link>
)

const EmployeeListHeader = ({
  employees,
  currentTab,
  setCurrentTab,
  setOpenInvitationModal,
}: EmployeeListHeaderProps) => (
  <div className="bg-white pt-6 px-14 border-b border-borderMedium">
    <div className="flex justify-between mb-4">
      <div>
        <Text type="title">{i18next.t('agency.home.employee_list_title')}</Text>
        <Text type="subtext">{i18next.t('agency.home.employee_list_description')}</Text>
      </div>
      <div>
        <Button
          type="button"
          onClick={() => {
            setOpenInvitationModal(true)
          }}
          name="Add employee"
        >
          <div className="flex items-center gap-1.5">
            <Icon name="add" uiStyle="light" />
            {i18next.t('agency.home.invite_employee')}
          </div>
        </Button>
      </div>
    </div>
    <div className="flex gap-8">
      <Tab
        label={i18next.t('agency.home.active_employees')}
        count={countEmployeeForStatus(employees, 'active')}
        isActive={currentTab === 'active'}
        tab="active"
        setCurrentTab={setCurrentTab}
      />
      <Tab
        label={i18next.t('agency.home.pending_invites')}
        count={countEmployeeForStatus(employees, 'invited')}
        isActive={currentTab === 'invited'}
        tab="invited"
        setCurrentTab={setCurrentTab}
      />
    </div>
  </div>
)

export default EmployeeListHeader
