import React from 'react'

const EmptyEmployeeListIllustration = () => (
  <svg width="218" height="146" viewBox="0 0 218 146" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_dii_26_215)">
      <rect x="5" y="99" width="208" height="40" rx="6" fill="white" />
      <rect x="4.5" y="98.5" width="209" height="41" rx="6.5" stroke="#001E5B" strokeOpacity="0.08" />
      <rect x="17" y="113" width="40" height="12" rx="4" fill="#ECECEC" />
      <rect x="65" y="113" width="68" height="12" rx="4" fill="#ECECEC" />
      <rect x="141" y="113" width="40" height="12" rx="6" fill="#FEE2E2" />
      <circle cx="147" cy="119" r="2" fill="#EF4444" />
      <rect x="189" y="113" width="12" height="12" rx="4" fill="#ECECEC" />
    </g>
    <g filter="url(#filter1_dii_26_215)">
      <rect x="5" y="51" width="208" height="40" rx="6" fill="white" />
      <rect x="4.5" y="50.5" width="209" height="41" rx="6.5" stroke="#001E5B" strokeOpacity="0.08" />
      <rect x="17" y="65" width="40" height="12" rx="4" fill="#ECECEC" />
      <rect x="65" y="65" width="68" height="12" rx="4" fill="#ECECEC" />
      <rect x="141" y="65" width="40" height="12" rx="6" fill="#DCFCE7" />
      <circle cx="147" cy="71" r="2" fill="#22C55E" />
      <rect x="189" y="65" width="12" height="12" rx="4" fill="#ECECEC" />
    </g>
    <g filter="url(#filter2_dii_26_215)">
      <rect x="5" y="3" width="208" height="40" rx="6" fill="white" />
      <rect x="4.5" y="2.5" width="209" height="41" rx="6.5" stroke="#001E5B" strokeOpacity="0.08" />
      <rect x="17" y="17" width="40" height="12" rx="4" fill="#ECECEC" />
      <rect x="65" y="17" width="68" height="12" rx="4" fill="#ECECEC" />
      <rect x="141" y="17" width="40" height="12" rx="6" fill="#FEF9C3" />
      <circle cx="147" cy="23" r="2" fill="#EAB308" />
      <rect x="189" y="17" width="12" height="12" rx="4" fill="#ECECEC" />
    </g>
    <defs>
      <filter
        id="filter0_dii_26_215"
        x="0"
        y="96"
        width="218"
        height="50"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.0933333 0 0 0 0 0.28 0 0 0 0.04 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_215" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26_215" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.0933333 0 0 0 0 0.28 0 0 0 0.08 0" />
        <feBlend mode="normal" in2="shape" result="effect2_innerShadow_26_215" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.960784 0 0 0 0 0.960784 0 0 0 0 0.960784 0 0 0 0.12 0" />
        <feBlend mode="normal" in2="effect2_innerShadow_26_215" result="effect3_innerShadow_26_215" />
      </filter>
      <filter
        id="filter1_dii_26_215"
        x="0"
        y="48"
        width="218"
        height="50"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.0933333 0 0 0 0 0.28 0 0 0 0.04 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_215" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26_215" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.0933333 0 0 0 0 0.28 0 0 0 0.08 0" />
        <feBlend mode="normal" in2="shape" result="effect2_innerShadow_26_215" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.960784 0 0 0 0 0.960784 0 0 0 0 0.960784 0 0 0 0.12 0" />
        <feBlend mode="normal" in2="effect2_innerShadow_26_215" result="effect3_innerShadow_26_215" />
      </filter>
      <filter
        id="filter2_dii_26_215"
        x="0"
        y="0"
        width="218"
        height="50"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.0933333 0 0 0 0 0.28 0 0 0 0.04 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_215" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26_215" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.0933333 0 0 0 0 0.28 0 0 0 0.08 0" />
        <feBlend mode="normal" in2="shape" result="effect2_innerShadow_26_215" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.960784 0 0 0 0 0.960784 0 0 0 0 0.960784 0 0 0 0.12 0" />
        <feBlend mode="normal" in2="effect2_innerShadow_26_215" result="effect3_innerShadow_26_215" />
      </filter>
    </defs>
  </svg>
)

export default EmptyEmployeeListIllustration
