import React from 'react'
import i18next from 'i18next'
import Button from 'components/Button'
import Text from 'components/Text'
import Icon from 'components/Icon'
import EmptyEmployeeListBackgroundMask from './EmptyEmployeeListBackgroundMask'
import EmptyEmployeeListIllustration from './EmptyEmployeeListIllustration'

type EmptyEmployeeListProps = {
  setOpenInvitationModal: (isOpen: boolean) => void
}

const EmptyEmployeeList = ({ setOpenInvitationModal }: EmptyEmployeeListProps) => (
  <div className="w-full h-full flex justify-center items-center row relative">
    <div className="w-1/3 text-center">
      <Text uiStyle="bold" type="text">
        {i18next.t('agency.home.empty_employee_list')}
      </Text>
      <Text className="mt-2" type="subtext">
        {i18next.t('agency.home.empty_employee_list_description')}
      </Text>
      <Button
        className="mt-6 mx-auto"
        type="button"
        onClick={() => {
          setOpenInvitationModal(true)
        }}
        name="Add employee"
      >
        <div className="flex items-center gap-1.5">
          <Icon name="add" uiStyle="light" />
          {i18next.t('agency.home.invite_employee')}
        </div>
      </Button>
    </div>
    <div className="absolute top-1/2 -translate-y-64">
      <EmptyEmployeeListIllustration />
    </div>
    <div className="absolute z-[-1] -translate-y-48">
      <EmptyEmployeeListBackgroundMask />
    </div>
  </div>
)

export default EmptyEmployeeList
