import React, { useEffect, useState } from 'react'
import i18next from 'i18next'

import { EmployeeWithInvitationStatus, RecordStatus } from 'agency/types/employees'
import { Agent } from 'agency/types/agents'
import useAgency from 'agency/contexts/AgencyContext'
import TableList from 'components/TableList'
import Text from 'components/Text'
import Input from 'components/Input'
import Select from 'components/Select'
import { Sort, SortKey, filterAndSortEmployees } from 'agency/utils/employee-filters'
import ActiveEmployeeRow from './EmployeeList/ActiveEmployeeRow'
import InvitedEmployeeRow from './EmployeeList/InvitedEmployeeRow'
import { ActiveHeader, InvitedHeader } from './EmployeeList/Headers'

type EmployeeListTableProps = {
  employees: EmployeeWithInvitationStatus[]
  agents: Agent[]
  currentTab: RecordStatus
}

const EmployeeListTable = ({ employees, agents, currentTab }: EmployeeListTableProps) => {
  const { currentAgencyId } = useAgency()
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [sort, setSort] = useState<Sort>({ key: 'invited_at', direction: 'desc' })
  const [selectedAgentId, setSelectedAgentId] = useState<string>()

  const filteredEmployees = filterAndSortEmployees(employees, sort, {
    searchQuery,
    agentId: selectedAgentId,
  })

  const onSortChange = (sortKey: SortKey) => {
    setSort((prevSort) => ({
      key: sortKey,
      direction: prevSort.key === sortKey ? (prevSort.direction === 'asc' ? 'desc' : 'asc') : 'asc',
    }))
  }

  const RowComponent = currentTab === 'active' ? ActiveEmployeeRow : InvitedEmployeeRow
  const HeaderComponent = currentTab === 'active' ? ActiveHeader : InvitedHeader

  useEffect(() => {
    setSelectedAgentId(undefined)
  }, [currentAgencyId])

  return (
    <div className="bg-backgroundPrimary w-full min-h-full p-12">
      <div className="flex flex-row items-center mb-2 gap-2">
        <Input
          onChange={setSearchQuery}
          name="search_query"
          placeholder={i18next.t('agency.home.employee_list.filters.search_employee')}
          type="text"
          className="min-w-64"
        />
        {agents.length > 0 && (
          <Select
            key={currentAgencyId}
            value={selectedAgentId}
            onValueChange={setSelectedAgentId}
            placeholder={i18next.t('agency.home.employee_list.filters.filter_by_agent')}
            options={[
              ...agents.map((agent: Agent) => ({ value: agent.id, label: `${agent.firstName} ${agent.lastName}` })),
              { value: 'all', label: i18next.t('agency.home.employee_list.filters.all_agents') },
            ]}
          />
        )}
      </div>
      <TableList>
        <HeaderComponent sort={sort} onSortChange={onSortChange} />
        {filteredEmployees.map((employee) => (
          <RowComponent key={employee.agency_employee_id} employee={employee} />
        ))}

        {filteredEmployees.length === 0 && (
          <TableList.Row>
            <Text>{i18next.t('agency.home.employee_list.filters.empty')}</Text>
          </TableList.Row>
        )}
      </TableList>
    </div>
  )
}

export default EmployeeListTable
