import React from 'react'
import i18next from 'i18next'
import { TDocument } from 'types/documents'
import { downloadFile } from 'utils/download-file'
import IconButton from './IconButton'

type DocumentDownloadButtonProps = {
  file: TDocument
}

const DocumentDownloadButton = ({ file }: DocumentDownloadButtonProps) => {
  const download = () => {
    const fullURL = new URL(file.url, window.location.origin)
    fullURL.searchParams.set('disposition', 'attachment')
    downloadFile(fullURL.toString())
  }

  return <IconButton name="download" label={i18next.t('common.download_document')} onClick={download} />
}

export default DocumentDownloadButton
