import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import httpClient from 'utils/http-client'
import { EmployeeFromEndpoint, EmployeeWithInvitationStatus } from 'agency/types/employees'
import useAgency from 'agency/contexts/AgencyContext'

export const EMPLOYEES_KEY = ['employees']

export const getEmployeesKey = (agencyId: string | null) => [...EMPLOYEES_KEY, agencyId]

export const getEmployeeKey = (agencyEmployeeId?: string) => [...EMPLOYEES_KEY, agencyEmployeeId]

export const useEmployee = (agencyEmployeeId?: string) => {
  const { currentAgencyId } = useAgency()

  return useQuery<EmployeeFromEndpoint>({
    queryKey: getEmployeeKey(agencyEmployeeId),
    queryFn: () =>
      httpClient<EmployeeFromEndpoint>(`/agencies/${currentAgencyId}/agency_employees/${agencyEmployeeId}`),
    initialData: {
      first_name: '',
      last_name: '',
      agency_employee_id: agencyEmployeeId ?? '',
      email: '',
      document_groups: [],
    },
    enabled: !!agencyEmployeeId,
  })
}

export const useInvalidateEmployeeQuery = () => {
  const queryClient = useQueryClient()
  return (agencyEmployeeId: string) => queryClient.invalidateQueries({ queryKey: getEmployeeKey(agencyEmployeeId) })
}

export const useEmployees = () => {
  const { currentAgencyId } = useAgency()

  return useQuery<EmployeeWithInvitationStatus[]>({
    queryKey: getEmployeesKey(currentAgencyId),
    queryFn: () => httpClient<EmployeeWithInvitationStatus[]>(`/agencies/${currentAgencyId}/agency_employees`),
    initialData: [],
    enabled: !!currentAgencyId,
  })
}

type AddEmployeePayload = { email: string; firstName: string; lastName: string; phoneNumber?: string }

type UseAddEmployeeArgs = {
  onSuccess: (employee: EmployeeWithInvitationStatus, params: AddEmployeePayload) => void
}

export const useAddEmployee = ({ onSuccess }: UseAddEmployeeArgs) => {
  const queryClient = useQueryClient()
  const { currentAgencyId } = useAgency()
  return useMutation({
    mutationFn: ({ email, firstName, lastName, phoneNumber }: AddEmployeePayload) =>
      httpClient<EmployeeWithInvitationStatus>(`/agencies/${currentAgencyId}/agency_employees`, {
        method: 'post',
        body: {
          email,
          first_name: firstName,
          last_name: lastName,
          phone_number: phoneNumber?.replaceAll(' ', '') ?? '',
        },
      }),
    onSuccess: (employee: EmployeeWithInvitationStatus, params: AddEmployeePayload) => {
      queryClient.setQueryData(getEmployeesKey(currentAgencyId), (employees: EmployeeWithInvitationStatus[]) => [
        ...employees,
        employee,
      ])
      onSuccess(employee, params)
    },
  })
}

export const useRemoveEmployee = () => {
  const queryClient = useQueryClient()
  const { currentAgencyId } = useAgency()

  return useMutation({
    mutationFn: (agencyEmployeeId: string) =>
      httpClient<void>(`/agencies/${currentAgencyId}/agency_employees/${agencyEmployeeId}`, {
        method: 'delete',
      }),
    onSuccess: (_data: void, agencyEmployeeId: string) => {
      queryClient.setQueryData(getEmployeesKey(currentAgencyId), (employees: EmployeeWithInvitationStatus[]) =>
        employees.filter((e) => e.agency_employee_id !== agencyEmployeeId)
      )
    },
  })
}
