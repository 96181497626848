import React from 'react'
import { DocumentGroup } from 'agency/types/document_groups'
import { DocumentGroupStatus } from 'types/document_groups'
import Text from 'components/Text'
import i18next from 'i18next'
import TableList from 'components/TableList'
import Tag, { TagUiStyle } from 'components/Tag'

import { UploadedDocument } from 'types/documents'
import classNames from 'classnames'
import DocumentRow, { MissingDocumentRow } from './DocumentRow'
import ActionButton from './ActionButton'
import VerificationIcon from './VerificationIcon'

const TAG_TYPE_BY_STATUS: Record<DocumentGroupStatus, TagUiStyle> = {
  valid: 'success',
  missing: 'error',
  expired: 'error',
  unreadable: 'error',
  non_compliant: 'error',
  missing_name: 'error',
  missing_double_sided: 'error',
  missing_date: 'error',
  missing_signature: 'error',
  fraudulent: 'error',
}

type DocumentGroupRowProps = {
  documentGroup: DocumentGroup
  expanded: boolean
  onDisplayClick: (documentId: string) => void
  onExpandClick: () => void
  onImportClick: (documentGroupId: string) => void
}

const DocumentGroupRow = ({
  documentGroup,
  expanded,
  onDisplayClick,
  onExpandClick,
  onImportClick,
}: DocumentGroupRowProps) => (
  <React.Fragment key={documentGroup.id}>
    <TableList.Row
      className={classNames('px-4 [&>*:first-child]:w-5/12>', { 'bg-backgroundPrimary': expanded })}
      ariaLabel={documentGroup.label}
    >
      <div className="flex flex-row items-center">
        <Text uiStyle="bold" type="text">
          {documentGroup.label}
        </Text>
        {documentGroup.documents.length === 1 && (
          <VerificationIcon
            status={documentGroup.documents[0].verification_status}
            slug={documentGroup.documents[0].kind_slug}
          />
        )}
      </div>
      {!expanded ? (
        <Tag uiStyle={TAG_TYPE_BY_STATUS[documentGroup.status]} withDot>
          {i18next.t(`document_groups.status.${documentGroup.status}`)}
        </Tag>
      ) : (
        <div />
      )}
      <Text type="text">-</Text>
      <div />

      <ActionButton
        documentGroup={documentGroup}
        expanded={expanded}
        onDisplayClick={onDisplayClick}
        onExpandClick={onExpandClick}
        onImportClick={onImportClick}
      />
    </TableList.Row>
    {expanded && (
      <>
        {documentGroup.documents.map((document: UploadedDocument) => (
          <DocumentRow
            key={document.id}
            document={document}
            onDisplayClick={() => onDisplayClick(document.id)}
            expanded
          />
        ))}
        {documentGroup.missing_required_documents_slugs.map((slug) => (
          <MissingDocumentRow key={slug} kind_slug={slug} expanded />
        ))}
      </>
    )}
  </React.Fragment>
)

export default DocumentGroupRow
