import { useEffect } from 'react'
import httpClient from 'utils/http-client'

type TokenFromEndpoint = {
  email: string
  token: string
}

const useSetupHubSpotChat = () => {
  useEffect(() => {
    const enabled = window.hsConversationsSettings?.loadImmediately === false
    if (!enabled) {
      return
    }

    httpClient<TokenFromEndpoint>('/chat_visitor_identification_tokens', { method: 'post' }).then(
      ({ email, token }) => {
        window.hsConversationsSettings = {
          identificationEmail: email,
          identificationToken: token,
        }
        if (window.HubSpotConversations) {
          window.HubSpotConversations.widget.load()
        } else {
          window.hsConversationsOnReady = [() => window.HubSpotConversations?.widget.load()]
        }
      }
    )
  }, [])
}

export default useSetupHubSpotChat
