type Order = 'asc' | 'desc'

export const sortBy = <T>(array: T[], key: keyof T, order: Order = 'asc'): T[] =>
  array.sort((a, b) => {
    const valueA = a[key]
    const valueB = b[key]

    if (valueA === valueB) {
      return 0
    }

    if (order === 'asc') {
      return valueA < valueB ? -1 : 1
    }
    return valueA > valueB ? -1 : 1
  })
