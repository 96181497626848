import ClickableDiv from 'components/ClickableDiv'
import Text from 'components/Text'
import i18next from 'i18next'
import React, { useRef } from 'react'

type UploadFieldProps = {
  onChange: (file: File) => void
}

const UploadField = ({ onChange }: UploadFieldProps) => {
  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <div className="m-8">
      <input
        id="capture_file"
        type="file"
        accept="image/*,.pdf"
        hidden
        onChange={(event) => {
          const file = event.target.files?.[0]
          if (!file) return
          onChange(file)
        }}
        ref={inputRef}
      />
      <ClickableDiv
        id="capture_file"
        label={i18next.t('agency.employee_profile.document_section.import_document')}
        className="border border-dashed bg-white p-6 rounded-lg cursor-pointer text-center w-full h-32 flex flex-col items-center justify-center"
        onClick={() => inputRef.current?.click()}
      >
        <Text type="subtitle" className="text-primary">
          {i18next.t('agency.employee_profile.document_section.import_document')}
        </Text>
        <Text type="subtext">{i18next.t('common.document_upload.accepted_formats')}</Text>
        <Text type="subtext">{i18next.t('common.document_upload.max_size')}</Text>
      </ClickableDiv>
    </div>
  )
}

export default UploadField
