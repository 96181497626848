import React from 'react'
import * as Tooltip from '@radix-ui/react-tooltip'
import Text from './Text'

type TooltipProps = {
  trigger: React.ReactNode
  content: string
  triggerClassName?: string
}

export default ({ trigger, content, triggerClassName }: TooltipProps) => (
  <Tooltip.Provider>
    <Tooltip.Root>
      <Tooltip.Trigger className={triggerClassName}>{trigger}</Tooltip.Trigger>
      <Tooltip.Portal>
        <Tooltip.Content className="bg-black px-3 py-2 rounded-md">
          <Text className="text-white">{content}</Text>
          <Tooltip.Arrow className="text-textPrimary" />
        </Tooltip.Content>
      </Tooltip.Portal>
    </Tooltip.Root>
  </Tooltip.Provider>
)
