import ClickableDiv from 'components/ClickableDiv'
import Text from 'components/Text'
import Tooltip from 'components/Tooltip'
import Icon from 'components/Icon'
import i18next from 'i18next'
import React from 'react'
import { Sort, SortKey } from 'agency/utils/employee-filters'
import TableList from 'components/TableList'

type SortButtonProps = {
  sortKey: SortKey
  label: string
  sort: Sort
  onSortChange: (sortKey: SortKey) => void
  text: string
}

const SortButton = ({ sortKey, label, sort, onSortChange, text }: SortButtonProps) => (
  <Tooltip
    triggerClassName="width-fit"
    trigger={
      <ClickableDiv
        label={label}
        className="flex items-center justify-start gap-2"
        onClick={() => onSortChange(sortKey)}
      >
        <Text uiStyle="bold" type="text" className="hover:text-textSecondary/50">
          {text}
        </Text>
        {sort.key === sortKey && <Icon name={sort.direction === 'asc' ? 'arrow_upward' : 'arrow_downward'} />}
      </ClickableDiv>
    }
    content={label}
  />
)

type HeaderProps = {
  sort: Sort
  onSortChange: (sort: SortKey) => void
}

export const ActiveHeader = ({ onSortChange, sort }: HeaderProps) => (
  <TableList.Header>
    <SortButton
      sortKey="name"
      label={i18next.t('agency.home.employee_list.sort_by_name')}
      sort={sort}
      onSortChange={onSortChange}
      text={i18next.t('agency.home.employee_list.name')}
    />
    <SortButton
      sortKey="document_status"
      label={i18next.t('agency.home.employee_list.sort_by_document_status')}
      sort={sort}
      onSortChange={onSortChange}
      text={i18next.t('agency.home.employee_list.document_status')}
    />
    <SortButton
      sortKey="invited_at"
      label={i18next.t('agency.home.employee_list.sort_by_invited_at')}
      sort={sort}
      onSortChange={onSortChange}
      text={i18next.t('agency.home.employee_list.invited_at')}
    />
  </TableList.Header>
)

export const InvitedHeader = ({ onSortChange, sort }: HeaderProps) => (
  <TableList.Header>
    <SortButton
      sortKey="name"
      label={i18next.t('agency.home.employee_list.sort_by_name')}
      sort={sort}
      onSortChange={onSortChange}
      text={i18next.t('agency.home.employee_list.name')}
    />
    <SortButton
      sortKey="document_status"
      label={i18next.t('agency.home.employee_list.sort_by_document_status')}
      sort={sort}
      onSortChange={onSortChange}
      text={i18next.t('agency.home.employee_list.invite_status')}
    />
    <Text uiStyle="bold" type="text">
      {i18next.t('common.email')}
    </Text>
    <Text uiStyle="bold" type="text">
      {i18next.t('common.phone_number')}
    </Text>
  </TableList.Header>
)
