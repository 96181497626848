import { useQuery } from '@tanstack/react-query'
import useAgency from 'agency/contexts/AgencyContext'
import { Agent } from 'agency/types/agents'
import httpClient from 'utils/http-client'

const AGENTS_KEY = ['agents']

const getAgentsKey = (agencyId: string | null) => [...AGENTS_KEY, agencyId]

type AgentIndexResponse = {
  id: string
  first_name: string
  last_name: string
  email: string
}

export const useAgents = () => {
  const { currentAgencyId } = useAgency()

  return useQuery<Agent[]>({
    queryKey: getAgentsKey(currentAgencyId),
    queryFn: () =>
      httpClient<AgentIndexResponse[]>(`/agencies/${currentAgencyId}/agents`).then((agents) =>
        agents.map(({ id, first_name, last_name, email }) => ({
          id,
          firstName: first_name,
          lastName: last_name,
          email,
        }))
      ),
    initialData: [],
    enabled: !!currentAgencyId,
  })
}
