import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import httpClient from 'utils/http-client'
import CommonResetPassword from 'components/ResetPassword'

const ResetPassword = () => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const token = new URLSearchParams(search).get('token')
  const expiredTokenUrl = '/agent/password/new?expired=true'

  if (!token) {
    navigate(expiredTokenUrl)
    return null
  }

  const resetPassword = (password: string) => {
    httpClient<void>('/agent/password', {
      method: 'put',
      body: { password, token },
    })
      .then(() => {
        navigate('/login')
      })
      .catch(() => navigate(expiredTokenUrl))
  }

  return (
    <div className="bg-backgroundPrimary min-h-screen flex">
      <CommonResetPassword className="max-w-3xl mx-auto" resetPassword={resetPassword} />
    </div>
  )
}

export default ResetPassword
