import { useQueryClient } from '@tanstack/react-query'
import { DocumentGroup } from 'agency/types/document_groups'
import { EmployeeFromEndpoint, EmployeeWithInvitationStatus } from 'agency/types/employees'
import useAgency from 'agency/contexts/AgencyContext'
import { getEmployeeKey, getEmployeesKey } from './employees'

export const useUpdateDocumentGroupsForEmployee = (agencyEmployeeId?: string) => {
  const { currentAgencyId } = useAgency()
  const queryClient = useQueryClient()

  return (documentGroups: DocumentGroup[]) => {
    queryClient.setQueryData(
      getEmployeeKey(agencyEmployeeId),
      (employee: EmployeeFromEndpoint): EmployeeFromEndpoint => ({ ...employee, document_groups: documentGroups })
    )
    queryClient.setQueryData(
      getEmployeesKey(currentAgencyId),
      (employees: EmployeeWithInvitationStatus[]): EmployeeWithInvitationStatus[] => {
        if (!employees) {
          return []
        }
        const impactedEmployee = employees.find((employee) => employee.agency_employee_id === agencyEmployeeId)

        if (!impactedEmployee) {
          return employees
        }

        return [
          ...employees.filter((employee) => employee.agency_employee_id !== agencyEmployeeId),
          {
            ...impactedEmployee,
            document_groups_slugs: documentGroups.map((documentGroup) => documentGroup.kind_slug),
          },
        ]
      }
    )
  }
}
