import React from 'react'
import { DocumentGroup } from 'agency/types/document_groups'
import Text from 'components/Text'
import i18next from 'i18next'
import Icon from 'components/Icon'

type DisplayButtonProps = { label: string; onClick: () => void }

export const DisplayButton = ({ onClick, label }: DisplayButtonProps) => (
  <button type="button" name={label} className="text-primary flex items-center gap-2 cursor-pointer" onClick={onClick}>
    <Icon className="text-primary" name="visibility" />
    <Text type="text" uiStyle="bold">
      {i18next.t('agency.employee_profile.document_section.document_list.display')}
    </Text>
  </button>
)

export const ImportButton = ({ onClick, label }: DisplayButtonProps) => (
  <button type="button" name={label} className="text-primary flex items-center gap-2 cursor-pointer" onClick={onClick}>
    <Icon className="text-primary" name="cloud_upload" />
    <Text type="text" uiStyle="bold">
      {i18next.t('agency.employee_profile.document_section.document_list.import')}
    </Text>
  </button>
)

type ActionButtonProps = {
  documentGroup: DocumentGroup
  onExpandClick: (id: string) => void
  onDisplayClick: (id: string) => void
  onImportClick: (id: string) => void
  expanded: boolean
}

const canToggleDocumentGroupRow = (documentGroup: DocumentGroup) =>
  documentGroup.documents.length > 1 || documentGroup.missing_required_documents_slugs.length > 0

const ActionButton = ({ documentGroup, onDisplayClick, onExpandClick, onImportClick, expanded }: ActionButtonProps) => {
  if (canToggleDocumentGroupRow(documentGroup)) {
    const label = i18next.t('agency.employee_profile.document_section.document_list.expand_for', {
      label: documentGroup.label,
    })
    return (
      <div className="flex justify-end">
        <button
          type="button"
          aria-label={label}
          name={label}
          className="text-primary flex items-center gap-2 cursor-pointer"
          onClick={() => {
            onExpandClick(documentGroup.id)
          }}
        >
          <Icon name={expanded ? 'expand_less' : 'expand_more'} size="large" />
        </button>
      </div>
    )
  }
  if (documentGroup.status !== 'missing') {
    return (
      <DisplayButton
        label={i18next.t('agency.employee_profile.document_section.document_list.display_for', {
          label: documentGroup.label,
        })}
        onClick={() => {
          onDisplayClick(documentGroup.documents[0].id)
        }}
      />
    )
  }

  return (
    <ImportButton
      label={i18next.t('agency.employee_profile.document_section.document_list.display_for', {
        label: documentGroup.label,
      })}
      onClick={() => {
        onImportClick(documentGroup.id)
      }}
    />
  )

  return <div />
}

export default ActionButton
