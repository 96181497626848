import React from 'react'
import i18next from 'i18next'
import dayjs from 'dayjs'

import Tag from 'components/Tag'
import { EmployeeWithInvitationStatus } from 'agency/types/employees'
import StatusTags from './StatusTags'

const EmployeeStatus = ({ employee }: { employee: EmployeeWithInvitationStatus }) => {
  switch (employee.status) {
    case 'active':
      return <StatusTags statusTags={employee.status_tags} />
    case 'invited':
      return (
        <div>
          <Tag uiStyle="warning" withDot>
            {i18next.t('agency.home.employee_list.invite_sent_at', {
              date: dayjs(employee.invited_at),
              interpolation: { escapeValue: false },
            })}
          </Tag>
        </div>
      )
  }
}

export default EmployeeStatus
