import React from 'react'

type CirclesIllustrationProps = {
  className?: string
}

const CirclesIllustration = ({ className }: CirclesIllustrationProps) => (
  <svg
    className={className}
    width="224"
    height="224"
    viewBox="0 0 224 224"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_156_21494"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="-128"
      y="-128"
      width="352"
      height="352"
    >
      <rect x="-128" y="-128" width="352" height="352" fill="url(#paint0_radial_156_21494)" />
    </mask>
    <g mask="url(#mask0_156_21494)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48 223C144.65 223 223 144.65 223 48C223 -48.6498 144.65 -127 48 -127C-48.6498 -127 -127 -48.6498 -127 48C-127 144.65 -48.6498 223 48 223ZM48 224C145.202 224 224 145.202 224 48C224 -49.2021 145.202 -128 48 -128C-49.2021 -128 -128 -49.2021 -128 48C-128 145.202 -49.2021 224 48 224Z"
        fill="#001E5B"
        fillOpacity="0.08"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48 191C126.977 191 191 126.977 191 48C191 -30.9767 126.977 -95 48 -95C-30.9767 -95 -95 -30.9767 -95 48C-95 126.977 -30.9767 191 48 191ZM48 192C127.529 192 192 127.529 192 48C192 -31.529 127.529 -96 48 -96C-31.529 -96 -96 -31.529 -96 48C-96 127.529 -31.529 192 48 192Z"
        fill="#001E5B"
        fillOpacity="0.08"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48 159C109.304 159 159 109.304 159 48C159 -13.3036 109.304 -63 48 -63C-13.3036 -63 -63 -13.3036 -63 48C-63 109.304 -13.3036 159 48 159ZM48 160C109.856 160 160 109.856 160 48C160 -13.8559 109.856 -64 48 -64C-13.8559 -64 -64 -13.8559 -64 48C-64 109.856 -13.8559 160 48 160Z"
        fill="#001E5B"
        fillOpacity="0.08"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48 127C91.6305 127 127 91.6305 127 48C127 4.36951 91.6305 -31 48 -31C4.36951 -31 -31 4.36951 -31 48C-31 91.6305 4.36951 127 48 127ZM48 128C92.1828 128 128 92.1828 128 48C128 3.81721 92.1828 -32 48 -32C3.81721 -32 -32 3.81721 -32 48C-32 92.1828 3.81721 128 48 128Z"
        fill="#001E5B"
        fillOpacity="0.08"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48 95C73.9574 95 95 73.9574 95 48C95 22.0426 73.9574 1 48 1C22.0426 1 1 22.0426 1 48C1 73.9574 22.0426 95 48 95ZM48 96C74.5097 96 96 74.5097 96 48C96 21.4903 74.5097 0 48 0C21.4903 0 0 21.4903 0 48C0 74.5097 21.4903 96 48 96Z"
        fill="#001E5B"
        fillOpacity="0.08"
      />
    </g>
    <defs>
      <radialGradient
        id="paint0_radial_156_21494"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(48 48) rotate(90) scale(176)"
      >
        <stop stopColor="#D9D9D9" />
        <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
)
export default CirclesIllustration
