import React from 'react'
import i18next from 'i18next'

import Dropdown from 'components/Dropdown'
import Icon from 'components/Icon'
import Text from 'components/Text'
import useAgency from 'agency/contexts/AgencyContext'
import { useNavigate } from 'react-router-dom'
import { sortBy } from 'utils/sort'
import { Agency } from 'agency/types/agency'

const AgenciesTrigger = () => {
  const { currentAgency } = useAgency()

  if (!currentAgency) return null

  return (
    <div className="flex items-center gap-4">
      <Text uiStyle="bold" className="text-lg text-textPrimary">
        {currentAgency.name}
      </Text>
      <Icon size="medium" name="expand_more" />
    </div>
  )
}

const AgenciesMenuItem = () => {
  const navigate = useNavigate()
  const { agencies, currentAgencyId, setCurrentAgencyId } = useAgency()

  const onAgencyClick = (agencyId: string) => {
    if (agencyId === currentAgencyId) return

    setCurrentAgencyId(agencyId)
    navigate(`/agencies/${agencyId}/agency_employees`)
  }

  if (agencies.length <= 1) return null

  return (
    <Dropdown trigger={<AgenciesTrigger />} label={i18next.t('agency.user_menu.change_agency')} className="min-w-48">
      {sortBy<Agency>(agencies, 'name').map((agency) => (
        <Dropdown.Item key={`agency-${agency.id}`} onClick={() => onAgencyClick(agency.id)}>
          <button type="button">
            <Text uiStyle="bold" className="text-textPrimary">
              {agency.name}
            </Text>
          </button>
        </Dropdown.Item>
      ))}
    </Dropdown>
  )
}

export default AgenciesMenuItem
