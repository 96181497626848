import React from 'react'

const EmptyEmployeeListBackgroundMask = () => (
  <svg width="424" height="424" viewBox="0 0 424 424" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_26_211"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="424"
      height="424"
    >
      <rect width="424" height="424" fill="url(#paint0_radial_26_211)" />
    </mask>
    <g mask="url(#mask0_26_211)">
      <path
        d="M20 0L20 424M424 20H0M52 0L52 424M424 52H0M84 0L84 424M424 84H0M116 0L116 424M424 116H0M148 0L148 424M424 148H0M180 0L180 424M424 180H0M212 0L212 424M424 212H0M244 0L244 424M424 244H0M276 0L276 424M424 276H0M308 0L308 424M424 308H0M340 0L340 424M424 340H0M372 0L372 424M424 372H0M404 0L404 424M424 404H0"
        stroke="#EBECEF"
      />
    </g>
    <defs>
      <radialGradient
        id="paint0_radial_26_211"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(212 212) rotate(90) scale(212)"
      >
        <stop stopColor="#D9D9D9" />
        <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
)

export default EmptyEmployeeListBackgroundMask
