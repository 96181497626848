import React from 'react'
import Modal from 'react-modal'
import i18next from 'i18next'

import { DocumentGroup, DocumentGroupKind } from 'agency/types/document_groups'
import DocumentGroupKindsSelect from 'agency/components/DocumentGroupKindsSelect'
import Divider from 'components/Divider'
import Button from 'components/Button'
import Icon from 'components/Icon'
import Text from 'components/Text'
import CirclesIllustration from 'agency/pages/EmployeeList/EmployeeInvitationModal/CirclesIllustration'
import { useAddDocumentGroupRequests } from 'agency/queries/documents'
import { useUpdateDocumentGroupsForEmployee } from 'agency/queries/employeesStateActions'

type RequestModalProps = {
  closeModal: () => void
  agencyEmployeeId: string
  existingDocumentGroupKindSlugs: string[]
}

const RequestModal = ({ closeModal, agencyEmployeeId, existingDocumentGroupKindSlugs }: RequestModalProps) => {
  const [selectedDocumentKinds, setSelectedDocumentKinds] = React.useState<DocumentGroupKind[]>(
    window.document_group_kinds.filter(
      (documentKind: DocumentGroupKind) =>
        documentKind.default || existingDocumentGroupKindSlugs.includes(documentKind.slug)
    )
  )

  const updateEmployee = useUpdateDocumentGroupsForEmployee(agencyEmployeeId)

  const { mutate: addDocumentGroupRequests } = useAddDocumentGroupRequests(agencyEmployeeId, {
    onSuccess: (newDocumentGroups: DocumentGroup[]) => {
      updateEmployee(newDocumentGroups)
      closeModal()
    },
  })

  return (
    <Modal
      isOpen
      onRequestClose={closeModal}
      overlayClassName="fixed inset-0 bg-modalBackrop flex justify-center items-center"
      className="bg-white rounded-md w-[36rem] relative h-4/5"
    >
      <form
        onSubmit={(event) => {
          event.preventDefault()
          addDocumentGroupRequests(selectedDocumentKinds.map((documentKind) => documentKind.slug))
        }}
        className="h-full"
      >
        <div className="flex flex-col justify-between h-full">
          <div className="overflow-y-auto px-6 pt-6">
            <div className="h-12 w-12 mb-10 flex items-center justify-center rounded-full border relative">
              <CirclesIllustration className="absolute -top-6 -left-6" />
              <Icon name="edit" />
            </div>
            <Text type="title" className="mb-2">
              {i18next.t('agency.employee_profile.document_section.document_request.title')}
            </Text>
            <Text type="subtext" className="mb-4">
              {i18next.t('agency.employee_profile.document_section.document_request.description')}
            </Text>
            <DocumentGroupKindsSelect
              selectedDocumentKinds={selectedDocumentKinds}
              setSelectedDocumentKinds={setSelectedDocumentKinds}
            />
          </div>
          <div className="px-6 pb-6">
            <Divider className="my-6" />
            <div className="flex justify-between">
              <Button type="button" uiStyle="secondary" name="cancel" onClick={closeModal}>
                {i18next.t('common.cancel')}
              </Button>
              <Button type="submit" name="ask_document_list">
                {i18next.t('agency.home.employee_invitation.validate_document_list')}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default RequestModal
