import React, { Dispatch, SetStateAction } from 'react'
import i18next from 'i18next'

import { isEmailValid } from 'utils/validators'
import Button from 'components/Button'
import Icon from 'components/Icon'
import Input from 'components/Input'
import Text from 'components/Text'
import { EmployeeWithInvitationStatus, EmployeeWithContactDetails } from 'agency/types/employees'
import Divider from 'components/Divider'
import { useAddEmployee } from 'agency/queries/employees'
import CirclesIllustration from './CirclesIllustration'

type InviteProps = {
  closeModal: () => void
  setInvitedEmployee: Dispatch<SetStateAction<EmployeeWithContactDetails | undefined>>
  onNext: () => void
}

const Invite = ({ closeModal, onNext, setInvitedEmployee }: InviteProps) => {
  const [errors, setErrors] = React.useState<{ email?: string }>({})
  const { mutate: addEmployee } = useAddEmployee({
    onSuccess: (employee: EmployeeWithInvitationStatus, { email }: { email: string }) => {
      setInvitedEmployee({ ...employee, email })
      onNext()
    },
  })

  return (
    <div className="p-6">
      <CirclesIllustration className="absolute top-0 left-0" />
      <div className="border rounded-full p-3 border-borderMedium h-12 w-12 mb-6">
        <Icon name="person_add" />
      </div>
      <Text type="title">{i18next.t('agency.home.employee_invitation.title')}</Text>
      <Text type="subtext">{i18next.t('agency.home.employee_invitation.description')}</Text>
      <form
        onSubmit={(event) => {
          event.preventDefault()

          // eslint-disable-next-line @typescript-eslint/naming-convention
          const { email, first_name, last_name, phone_number } = event.target as typeof event.target & {
            email: { value: string }
            first_name: { value: string }
            last_name: { value: string }
            phone_number: { value: string }
          }

          if (!isEmailValid(email.value)) {
            setErrors({ email: i18next.t('common.errors.invalid_email') })
            return
          }

          addEmployee({
            email: email.value,
            firstName: first_name.value,
            lastName: last_name.value,
            phoneNumber: phone_number.value,
          })
        }}
      >
        <div className="flex gap-4 mt-6 mb-4 justify-between">
          <Input className="w-1/2" name="first_name" label={i18next.t('common.first_name')} type="text" required />
          <Input className="w-1/2" name="last_name" label={i18next.t('common.last_name')} type="text" required />
        </div>
        <div className="flex gap-4 justify-between">
          <Input
            className="w-1/2"
            name="email"
            label={i18next.t('common.email')}
            type="email"
            required
            onChange={() => setErrors({})}
            error={errors.email}
          />
          <Input className="w-1/2" name="phone_number" label={i18next.t('common.phone_number')} type="tel" />
        </div>
        <Divider className="my-6" />
        <div className="flex justify-between">
          <Button onClick={closeModal} type="button" name="cancel" uiStyle="secondary">
            {i18next.t('common.cancel')}
          </Button>
          <Button type="submit" name="invite">
            {i18next.t('agency.home.employee_invitation.send')}
          </Button>
        </div>
      </form>
    </div>
  )
}

export default Invite
