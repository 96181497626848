import { DocumentGroup } from 'agency/types/document_groups'

type Employee = {
  first_name: string
  last_name: string
  agency_employee_id: string
  phone_number?: string
}

export type EmployeeWithContactDetails = Employee & {
  email: string
}

export type EmployeeFromEndpoint = EmployeeWithContactDetails & {
  document_groups: DocumentGroup[]
}

export type StatusTag = 'complete' | 'missing' | 'added' | 'fraudulent' | 'finalizable'
export type StatusTagsByCount = Partial<Record<StatusTag, number>>

export type EmployeeWithInvitationStatus = Employee & {
  status: RecordStatus
  document_groups_slugs: string[]
  status_tags: StatusTagsByCount
  invited_at: string
  agent_id?: string
  email: string
  phone_number?: string
}

const RECORD_STATUS = ['active', 'invited'] as const
export type RecordStatus = (typeof RECORD_STATUS)[number]
export const isRecordStatus = (value: string): value is RecordStatus => RECORD_STATUS.includes(value as RecordStatus)
