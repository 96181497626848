import React, { useEffect, useRef } from 'react'
import i18next from 'i18next'
import Fuse from 'fuse.js'

import { DocumentGroupKind } from 'agency/types/document_groups'
import Tag from 'components/Tag'
import Icon from 'components/Icon'
import { removeAccents } from 'utils/string'

const DOCUMENT_GROUP_KINDS_TO_SUGGEST = 10
const DOCUMENT_GROUP_KIND_SEARCH = new Fuse(window.document_group_kinds, {
  keys: ['label'],
  getFn: (documentKind) => removeAccents(documentKind.label),
})

type DocumentGroupKindsSelectProps = {
  selectedDocumentKinds: DocumentGroupKind[]
  setSelectedDocumentKinds: React.Dispatch<React.SetStateAction<DocumentGroupKind[]>>
}

const DocumentGroupKindsSelect = ({
  selectedDocumentKinds,
  setSelectedDocumentKinds,
}: DocumentGroupKindsSelectProps) => {
  const [search, setSearch] = React.useState('')
  const [suggestedDocumentKinds, setSuggestedDocumentKinds] = React.useState<DocumentGroupKind[]>([])
  const searchInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (search) {
      setSuggestedDocumentKinds(
        DOCUMENT_GROUP_KIND_SEARCH.search(search)
          .map((result) => result.item)
          .filter((documentKind) => !selectedDocumentKinds.includes(documentKind))
      )
    } else {
      setSuggestedDocumentKinds(
        window.document_group_kinds
          .filter((documentKind) => !selectedDocumentKinds.includes(documentKind))
          .slice(0, DOCUMENT_GROUP_KINDS_TO_SUGGEST)
      )
    }
  }, [search, selectedDocumentKinds])

  return (
    <div>
      <div className="border border-medium rounded-lg shadow flex flex-col px-3 py-2 mb-4">
        <div className="flex gap-2 mb-4 flex-wrap">
          {selectedDocumentKinds.map((documentKind) => (
            <Tag
              key={documentKind.slug}
              uiStyle="info"
              className="flex items-center"
              onClick={
                documentKind.default
                  ? undefined
                  : () => {
                      setSelectedDocumentKinds((currentDocumentKinds) =>
                        currentDocumentKinds.filter(
                          (currentDocumentKind) => currentDocumentKind.slug !== documentKind.slug
                        )
                      )
                      searchInputRef.current?.focus()
                    }
              }
              name={
                documentKind.default
                  ? undefined
                  : i18next.t('agency.home.employee_invitation.remove_document', { label: documentKind.label })
              }
            >
              {documentKind.label}
              {documentKind.default ? null : <Icon name="close" uiStyle="info" />}
            </Tag>
          ))}
        </div>
        <input
          ref={searchInputRef}
          name={i18next.t('agency.home.employee_invitation.search_document_kind')}
          className="outline-none text-textSecondary"
          placeholder={i18next.t('agency.home.employee_invitation.search_document_kind')}
          type="text"
          style={{ outline: 'none' }}
          value={search}
          onChange={(event) => {
            setSearch(event.target.value)
          }}
        />
      </div>
      <div className="flex flex-wrap gap-2">
        {suggestedDocumentKinds.map((suggestDocumentKind) => (
          <Tag
            key={suggestDocumentKind.slug}
            uiStyle="neutral"
            onClick={() => {
              setSelectedDocumentKinds((currentDocumentKinds) => [...currentDocumentKinds, suggestDocumentKind])
              setSearch('')
              searchInputRef.current?.focus()
            }}
            name={`Add ${suggestDocumentKind.label}`}
          >
            + {suggestDocumentKind.label}
          </Tag>
        ))}
      </div>
    </div>
  )
}

export default DocumentGroupKindsSelect
