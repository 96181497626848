import React from 'react'
import Text from 'components/Text'
import { EmployeeWithInvitationStatus } from 'agency/types/employees'
import { capitalizeFirstLetter } from 'utils/string'
import EmployeeRow from './EmployeeRow'
import EmployeeStatus from './EmployeeStatus'
import InvitedEmployeeActionButton from '../InvitedEmployeeActionButton'

type InvitedEmployeeRowProps = {
  employee: EmployeeWithInvitationStatus
}

const InvitedEmployeeRow = ({ employee }: InvitedEmployeeRowProps) => (
  <EmployeeRow key={employee.agency_employee_id} employee={employee}>
    <Text type="text" className="employee-name">
      {employee.last_name.toUpperCase()} {capitalizeFirstLetter(employee.first_name)}
    </Text>
    <EmployeeStatus employee={employee} />
    <Text type="text">{employee.email}</Text>
    <Text type="text">{employee.phone_number ? employee.phone_number : '-'}</Text>
    <InvitedEmployeeActionButton employee={employee} />
  </EmployeeRow>
)

export default InvitedEmployeeRow
