import useAgency from 'agency/contexts/AgencyContext'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const Home = () => {
  const navigate = useNavigate()
  const { currentAgencyId } = useAgency()

  useEffect(() => {
    if (window.logged_in) {
      if (currentAgencyId) {
        navigate(`/agencies/${currentAgencyId}/agency_employees`)
      } else {
        navigate('/agency_employees')
      }
    } else {
      navigate('/login')
    }
  }, [])

  return null
}

export default Home
