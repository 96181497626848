import React from 'react'
import Modal from 'react-modal'
import classNames from 'classnames'

import { EmployeeWithContactDetails } from 'agency/types/employees'
import Invite from './EmployeeInvitationModal/Invite'
import Documents from './EmployeeInvitationModal/Documents'

type EmployeeInvitationModalProps = {
  closeModal: () => void
}

const EmployeeInvitationModal = ({ closeModal }: EmployeeInvitationModalProps) => {
  const [step, setStep] = React.useState<'invite' | 'documents'>('invite')
  const [invitedEmployee, setInvitedEmployee] = React.useState<EmployeeWithContactDetails | undefined>()
  return (
    <Modal
      isOpen
      onRequestClose={closeModal}
      overlayClassName="fixed inset-0 bg-modalBackrop flex justify-center items-center"
      className={classNames('bg-white rounded-md w-[36rem] relative', {
        'h-4/5': step === 'documents',
      })}
    >
      {step === 'invite' ? (
        <Invite closeModal={closeModal} setInvitedEmployee={setInvitedEmployee} onNext={() => setStep('documents')} />
      ) : null}
      {step === 'documents' && invitedEmployee ? <Documents employee={invitedEmployee} onNext={closeModal} /> : null}
    </Modal>
  )
}

export default EmployeeInvitationModal
