import React, { useState } from 'react'
import { DocumentGroup } from 'agency/types/document_groups'
import Text from 'components/Text'
import i18next from 'i18next'
import Divider from 'components/Divider'
import TableList from 'components/TableList'

import DocumentGroupListHeader from './DocumentGroupList/DocumentGroupListHeader'
import DocumentRow from './DocumentGroupList/DocumentRow'
import DocumentGroupRow from './DocumentGroupList/DocumentGroupRow'

type TDocumentGroupListProps = {
  documentGroups: DocumentGroup[]
  agencyEmployeeId: string
  setDisplayDocumentPreviewForDocumentId: (documentId: string) => void
  setDocumentGroupsUploadChoices: (documentGroups: DocumentGroup[]) => void
}

const showOnlyOneDocument = (documentGroup: DocumentGroup) =>
  documentGroup.documents.length === 1 && documentGroup.missing_required_documents_slugs.length === 0

const DocumentGroupList = ({
  documentGroups,
  agencyEmployeeId,
  setDisplayDocumentPreviewForDocumentId,
  setDocumentGroupsUploadChoices,
}: TDocumentGroupListProps) => {
  const [expandedDocumentGroupIds, setExpandedDocumentGroupIds] = useState<string[]>([])

  const onExpandClick = (documentGroupId: string) =>
    setExpandedDocumentGroupIds((documentGroupIds) =>
      documentGroupIds.includes(documentGroupId)
        ? documentGroupIds.filter((id) => id !== documentGroupId)
        : [...documentGroupIds, documentGroupId]
    )

  return (
    <div className="bg-white grow h-fit border border-borderMedium rounded-lg">
      <DocumentGroupListHeader
        setDocumentGroupsUploadChoices={setDocumentGroupsUploadChoices}
        documentGroups={documentGroups}
        agencyEmployeeId={agencyEmployeeId}
      />
      <Divider />
      <TableList>
        <TableList.Header className="px-4">
          <Text type="text">{i18next.t('agency.employee_profile.document_section.document_list.kind')}</Text>
          <Text type="text">{i18next.t('agency.employee_profile.document_section.document_list.status')}</Text>
          <Text type="text">{i18next.t('agency.employee_profile.document_section.document_list.expiration_date')}</Text>
          <div />
          <Text type="text">{i18next.t('agency.employee_profile.document_section.document_list.documents')}</Text>
        </TableList.Header>
        {documentGroups.map((documentGroup) => {
          const expanded = expandedDocumentGroupIds.includes(documentGroup.id)

          if (showOnlyOneDocument(documentGroup)) {
            const document = documentGroup.documents[0]
            return (
              <DocumentRow
                key={documentGroup.id}
                document={document}
                onDisplayClick={() => setDisplayDocumentPreviewForDocumentId(document.id)}
                expanded={expanded}
              />
            )
          }
          return (
            <DocumentGroupRow
              key={documentGroup.id}
              documentGroup={documentGroup}
              expanded={expanded}
              onDisplayClick={setDisplayDocumentPreviewForDocumentId}
              onExpandClick={() => onExpandClick(documentGroup.id)}
              onImportClick={() => setDocumentGroupsUploadChoices([documentGroup])}
            />
          )
        })}
      </TableList>
    </div>
  )
}

export default DocumentGroupList
