import { useMutation, useQuery } from '@tanstack/react-query'
import { DocumentGroup } from 'agency/types/document_groups'
import useAgency from 'agency/contexts/AgencyContext'
import { TDocument } from 'types/documents'
import httpClient from 'utils/http-client'
import postFile from 'utils/post-file'
import { useInvalidateEmployeeQuery } from './employees'

const DOCUMENTS_KEY = ['documents']

const getDocumentKey = (documentId: string) => [...DOCUMENTS_KEY, documentId]

export const useDocument = (agencyEmployeeId: string, documentId: string) => {
  const { currentAgencyId } = useAgency()
  return useQuery({
    queryKey: getDocumentKey(documentId),
    queryFn: () =>
      httpClient<TDocument>(
        `/agencies/${currentAgencyId}/agency_employees/${agencyEmployeeId}/documents/${documentId}`
      ),
  })
}

type UseDocumentUploadArgs = { onSuccess?: () => void; onError?: (error: Error) => void }

type UseDocumentUploadMutationParams = {
  agencyEmployeeId: string
  documentGroupKindSlug: string
  documentKindSlug: string
  file: File
}

export const useDocumentUpload = ({ onSuccess, onError }: UseDocumentUploadArgs = {}) => {
  const invalidateEmployeeQuery = useInvalidateEmployeeQuery()
  const { currentAgencyId } = useAgency()

  return useMutation({
    mutationFn: ({
      agencyEmployeeId,
      documentGroupKindSlug,
      documentKindSlug,
      file,
    }: UseDocumentUploadMutationParams) =>
      postFile<void>(`/agencies/${currentAgencyId}/agency_employees/${agencyEmployeeId}/documents`, file, {
        document_group_kind_slug: documentGroupKindSlug,
        document_kind_slug: documentKindSlug,
      }),
    onSuccess: (_document: void, { agencyEmployeeId }: UseDocumentUploadMutationParams) => {
      invalidateEmployeeQuery(agencyEmployeeId)
      if (onSuccess) {
        onSuccess()
      }
    },
    onError,
  })
}

export const useAddDocumentGroupRequests = (
  agencyEmployeeId: string,
  { onSuccess }: { onSuccess?: (documentGroup: DocumentGroup[]) => void } = {}
) => {
  const { currentAgencyId } = useAgency()
  return useMutation({
    mutationFn: (selectedDocumentKinds: string[]) =>
      httpClient<DocumentGroup[]>(
        `/agencies/${currentAgencyId}/agency_employees/${agencyEmployeeId}/document_groups_request`,
        {
          method: 'post',
          body: {
            document_slugs: selectedDocumentKinds,
          },
        }
      ),
    onSuccess,
  })
}
