import React from 'react'
import classNames from 'classnames'
import Button from 'components/Button'
import Icon, { IconName } from 'components/Icon'

type IconTextButtonProps = {
  label: string
  onClick: () => void
  icon: IconName
  responsive?: boolean
}

const IconTextButton = ({ label, onClick, icon, responsive = false }: IconTextButtonProps) => (
  <Button
    type="button"
    uiStyle="secondary"
    name={label}
    ariaLabel={label}
    className="flex items-center gap-2"
    onClick={onClick}
  >
    <Icon name={icon} className="shrink-0" />
    <span className={classNames({ 'hidden lg:inline': responsive })}>{label}</span>
  </Button>
)

export default IconTextButton
