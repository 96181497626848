import React from 'react'
import classnames from 'classnames'
import * as RadixSelect from '@radix-ui/react-select'
import Icon from 'components/Icon'
import Text from './Text'

const SelectSeparator = () => <RadixSelect.Separator className="border border-borderMedium m-2" />

type SelectItemProps = {
  children: React.ReactNode
  className?: string
  value: string
  disabled?: boolean
}

const SelectItem = React.forwardRef(({ children, className, value, disabled }: SelectItemProps, forwardedRef) => (
  <RadixSelect.Item
    className={classnames(
      'leading-none rounded-0.25 flex items-center h-6 pr-8 pl-7 py-4 relative select-none data-[disabled]:text-textSecondary data-[disabled]:pointer-events-none data-[highlighted]:outline-none data-[highlighted]:bg-backgroundPrimary cursor-pointer rounded',
      className
    )}
    value={value}
    disabled={disabled}
    // @ts-expect-error TODO Apply correct type
    ref={forwardedRef}
  >
    <RadixSelect.ItemText>{children}</RadixSelect.ItemText>
    <RadixSelect.ItemIndicator className="absolute left-0 w-7 inline-flex items-center justify-center">
      <Icon name="check" />
    </RadixSelect.ItemIndicator>
  </RadixSelect.Item>
))

export type Item = {
  label: string
  value: string
}

type SelectProps = {
  options: Item[]
  onValueChange: (slug: string) => void
  placeholder?: string
  className?: string
  defaultValue?: string
  value?: string
  label?: string
  containerClassName?: string
}

const Select = ({
  options,
  onValueChange,
  placeholder,
  defaultValue,
  className,
  label,
  containerClassName,
  value,
}: SelectProps) => (
  <RadixSelect.Root onValueChange={onValueChange} defaultValue={defaultValue} value={value}>
    <div className={containerClassName}>
      {label && <Text uiStyle="bold">{label}</Text>}
      <RadixSelect.Trigger
        className={classnames(
          'inline-flex items-center justify-center leading-none px-3 py-2 h-[42px] bg-white border rounded-lg data-[placeholder]:text-textPlaceholder',
          className
        )}
        aria-label={placeholder}
      >
        <RadixSelect.Value placeholder={placeholder} />
        <RadixSelect.Icon>
          <Icon name="expand_more" />
        </RadixSelect.Icon>
      </RadixSelect.Trigger>
      <RadixSelect.Portal>
        <RadixSelect.Content className="overflow-hidden bg-white rounded-md drop-shadow border border-borderMedium">
          <RadixSelect.ScrollUpButton className="flex items-center justify-center h-2 bg-white cursor-default">
            <Icon name="expand_less" />
          </RadixSelect.ScrollUpButton>
          <RadixSelect.Viewport className="p-1.5">
            <RadixSelect.Group className="flex items-center justify-center text-textPlaceholder">
              <RadixSelect.Label className="">{placeholder}</RadixSelect.Label>
            </RadixSelect.Group>
            <SelectSeparator />
            {options.map((option: Item) => (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
              </SelectItem>
            ))}
          </RadixSelect.Viewport>
          <RadixSelect.ScrollDownButton className="flex items-center justify-center h-7 bg-white cursor-default">
            <Icon name="expand_more" />
          </RadixSelect.ScrollDownButton>
        </RadixSelect.Content>
      </RadixSelect.Portal>
    </div>
  </RadixSelect.Root>
)

export default Select
