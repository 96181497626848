import React from 'react'
import i18next from 'i18next'
import { Outlet } from 'react-router-dom'
import httpClient from 'utils/http-client'
import SkribeLogo from 'components/SkribeLogo'
import useAgent from 'agency/providers/useAgent'
import Chip from 'components/Chip'
import Dropdown from 'components/Dropdown'
import Icon from 'components/Icon'
import Text from 'components/Text'
import { Agent } from 'agency/types/agents'
import useAgency from 'agency/contexts/AgencyContext'
import AgenciesMenuItem from './Skeleton/AgenciesMenuItem'

type AgentChipProps = {
  agent: Agent
  subtext: string | null
}

const AgentChip = ({ agent, subtext }: AgentChipProps) => {
  const fullName = `${agent.firstName} ${agent.lastName}`
  const initials = `${agent.firstName[0].toUpperCase()}${agent.lastName[0].toUpperCase()}`
  return (
    <div className="flex gap-4 items-center">
      <Chip className="bg-avatarBackground">
        <Text type="text" uiStyle="bold" className="text-avatarText">
          {initials}
        </Text>
      </Chip>
      <div>
        <Text type="text" className="text-black">
          {fullName}
        </Text>
        {subtext && <Text type="subtext">{subtext}</Text>}
      </div>
    </div>
  )
}

const MenuTrigger = ({ agent }: { agent: Agent }) => {
  const { currentAgency, agencies } = useAgency()

  if (!currentAgency) return null

  const agencyName = agencies.length === 1 ? currentAgency.name : null

  return (
    <div className="flex items-center gap-4">
      <AgentChip agent={agent} subtext={agencyName} />
      <Icon name="more_horiz" />
    </div>
  )
}

const Skeleton = ({ children }: React.PropsWithChildren) => {
  const { agent } = useAgent()

  const logout = () => {
    httpClient('/session', { method: 'delete' }).then(() => {
      window.location.href = '/'
    })
  }

  return (
    <div className="w-screen h-screen relative">
      <header className="menu">
        <div className="w-full h-20 py-6 px-9 border-b border-borderStrong flex justify-between items-center">
          <a href="/" aria-label="Home">
            <SkribeLogo />
          </a>
          {agent ? (
            <div className="flex gap-4 items-center text-textSecondary">
              <AgenciesMenuItem />

              <hr className="rotate-90 w-8" />

              <Dropdown
                trigger={<MenuTrigger agent={agent} />}
                label={i18next.t('agency.user_menu.label')}
                className="min-w-64"
              >
                <Dropdown.Label>
                  <AgentChip agent={agent} subtext={agent.email} />
                </Dropdown.Label>

                <Dropdown.Separator />
                <Dropdown.Item onClick={logout}>
                  <button type="button" className="text-error">
                    <Icon name="logout" uiStyle="error" className="mr-4" />
                    {i18next.t('common.log_out')}
                  </button>
                </Dropdown.Item>
              </Dropdown>
            </div>
          ) : null}
        </div>
      </header>
      <main className="h-[calc(100vh-5rem)]">{children ?? <Outlet />}</main>
    </div>
  )
}

export default Skeleton
