import React from 'react'
import Text from 'components/Text'
import { EmployeeWithInvitationStatus } from 'agency/types/employees'
import { capitalizeFirstLetter } from 'utils/string'
import dayjs from 'dayjs'
import i18next from 'i18next'
import Icon from 'components/Icon'
import EmployeeRow from './EmployeeRow'
import EmployeeStatus from './EmployeeStatus'

type ActiveEmployeeRowProps = {
  employee: EmployeeWithInvitationStatus
}

const ActiveEmployeeRow = ({ employee }: ActiveEmployeeRowProps) => (
  <EmployeeRow key={employee.agency_employee_id} employee={employee}>
    <Text type="text" className="employee-name">
      {employee.last_name.toUpperCase()} {capitalizeFirstLetter(employee.first_name)}
    </Text>
    <EmployeeStatus employee={employee} />
    <div>
      <Text type="text" className="text-textSecondary">
        {dayjs(employee.invited_at).format('DD/MM/YYYY')}
      </Text>
    </div>
    <div className="text-primary">
      <div className="flex justify-end items-center">
        <Text type="text" className="inline">
          {i18next.t('agency.home.employee_list.view')}
        </Text>
        <Icon name="chevron_right" uiStyle="accent" />
      </div>
    </div>
  </EmployeeRow>
)

export default ActiveEmployeeRow
